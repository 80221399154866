import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { RouteKeys } from '~/router/routes.domain.ts';

export const AdminRemoteWorkRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_REMOTE_WORK,
	path: 'remote-work',
	component: () => import('~/pages/mobilities/remote-work.vue'),
	meta: {
		isForbidden: () => {
			return computed(() => !useFeatures().hasLeaveDayAdminFullFeature.value);
		}
	}
};
