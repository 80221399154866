import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';

import { AdminAutomaticEarnedWageAccessRoute } from './automatic-earned-wage-access/automatic-earned-wage-access.route';
import { AdminEarnedWageAccessRoute } from './earned-wage-access/earned-wage-access.route';
import { PayslipsValidationRoute } from './payslips-validation/payslips-validation.route';
import { PayrollValidationRoute } from './validation/payroll-validation.route';
import { VariableElementsRoute } from './variable-elements/variable-elements.route';

export const PayrollDashboardRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL_DASHBOARD,
	path: '',
	components: {
		default: () => import('./payroll-dashboard.vue'),
		...defaultLayoutComponents
	},
	meta: {
		requiresAuth: true
	}
};

export const PayrollRoute: RouteRecordRaw = {
	name: RouteKeys.PAYROLL,
	path: '/payroll',
	components: {
		default: () => import('./payroll.vue'),
		...defaultLayoutComponents
	},
	children: [
		PayrollDashboardRoute,
		VariableElementsRoute,
		AdminAutomaticEarnedWageAccessRoute,
		AdminEarnedWageAccessRoute,
		PayrollValidationRoute,
		PayslipsValidationRoute
	],
	redirect: PayrollDashboardRoute,
	meta: {
		isForbidden: () =>
			computed(
				() =>
					!(
						useFeatures().hasVariableElementsBonusesFeature.value ||
						useFeatures().hasVariableElementsHoursFeature.value ||
						useFeatures().hasVariableElementsValidationFeature.value ||
						useFeatures().hasEarnedWageAccessFeature.value ||
						useFeatures().hasPayslipFullCompanyViewFeature.value
					)
			),
		isNavEntry: true,
		label: 'admin.navigation.payroll',
		icon: () => $i('cycle-paie'),
		requiresAuth: true,
		order: 20
	}
};
