export function isValidFrenchSSN(value: string): boolean {
	if (!value) {
		return true; // Allow empty values, control with 'required' rule if needed and not with this function!
	}
	if (value.length !== 15) {
		return false;
	}

	// Replace potientialy Corsican department codes
	const SSN = value.slice(0, 15).replace(/2A/i, '19').replace(/2B/i, '18');

	// Check if the number is 15 digits long
	if (!/^\d{15}$/.test(SSN)) {
		return false;
	}

	const gender = parseInt(SSN.slice(0, 1), 10);
	if (gender !== 1 && gender !== 2) {
		return false; // Check gender (1 or 2)
	}

	const numWithoutKey = SSN.slice(0, 13); // Number without key
	const key = parseInt(SSN.slice(13, 15)); // Control key
	const expectedKey = 97 - (parseInt(numWithoutKey, 10) % 97);

	return key === expectedKey; // Check control key
}

export function matchBirthdateToFrenchSSN(value?: string, birthdate?: Date): boolean {
	if (!value || !birthdate) {
		return true; // Allow empty values, control with 'required' rule if needed
	}

	// Extract from the SSN
	const year = parseInt(value.slice(1, 3), 10); // Year of birth
	const month = parseInt(value.slice(3, 5), 10); // Month of birth

	// Validate date coherence
	const birthYear = birthdate.getFullYear() % 100; // Last two digits of birth year
	const birthMonth = birthdate.getMonth() + 1; // Months are indexed from 0, so we add 1.

	return year === birthYear && month === birthMonth;
}

export function matchDepartmentOfBirthToFrenchSSN(value?: string, department?: string): boolean {
	if (!value || !department) {
		return true; // Allow empty values, control with 'required' rule if needed
	}

	const departmentCodeInSSN = value.slice(5, 7);
	// DOM-TOM and some codes are not checked to be Silae Pay compliant.
	const ignoredCodes = ['96', '97', '98', '99'];

	if (ignoredCodes.includes(departmentCodeInSSN)) {
		return true;
	}

	return departmentCodeInSSN === department;
}
