import { Settings } from 'luxon';

export type LuxonConfig = {
	locale: string;
};

const DEFAULT_CONF: LuxonConfig = {
	locale: 'fr'
};

export function updateLuxonConfiguration(config: LuxonConfig = DEFAULT_CONF) {
	const conf = { ...DEFAULT_CONF, ...config };

	const locale = conf?.locale == 'en' ? 'en-GB' : conf?.locale;

	Settings.defaultLocale = locale;
}
