import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { LEAVE_DAYS_MODE, REMOTE_WORK_MODE } from '~/pages/agenda/agenda.domain.ts';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout';

export const AgendaRemoteWorkRoute: RouteRecordRaw = {
	name: RouteKeys.AGENDA_REMOTE_WORK,
	path: '/agenda/remote-work',
	component: () => import('./agenda.vue'),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => isAdmin.value);
		}
	},
	props: route => ({ start: route.query.s, scope: route.query.gt, mode: REMOTE_WORK_MODE })
};

export const AgendaLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.AGENDA_LEAVE_DAYS,
	path: '/agenda/leave-days',
	component: () => import('./agenda.vue'),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => isAdmin.value);
		}
	},
	props: route => ({ start: route.query.s, scope: route.query.gt, mode: LEAVE_DAYS_MODE })
};

export const AgendaRoute: RouteRecordRaw = {
	name: RouteKeys.AGENDA,
	path: '/agenda',
	components: {
		default: () => import('./agenda-router.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { hasSelfOnboardingFeature } = useFeatures();
			return computed(() => isAdmin.value || hasSelfOnboardingFeature.value || Devices().isMobile);
		},

		label: 'agenda.navigation',
		icon: () => $i('calendar'),
		isNavEntry: true,
		order: 2,
		requiresAuth: true
	},
	children: [AgendaRemoteWorkRoute, AgendaLeaveDaysRoute],
	redirect: () => AgendaLeaveDaysRoute
};
