import { ISODateString } from '../defs';

export type PayslipValidationDto = {
	canApplyActions: boolean;
	cancellationReason: CancellationReason;
	comment: string;
	employeeId: number;
	filename: string;
	firstName: string;
	id: number;
	lastName: string;
	period: ISODateString;
	receivedDate: ISODateString;
	registrationNumber: string;
	status: PayslipValidationState;
	institutionName: string;
};

export type PayslipsValidationBulkActionsRequest = {
	payslipsValidationIds: Array<number>;
	comment?: string;
};

export enum PayslipsValidationAction {
	BULK_VALIDATE = 'bulk_validate',
	BULK_REFUSE = 'bulk_refuse',
	VALIDATE = 'validate',
	REFUSE = 'refuse'
}

export enum PayslipValidationState {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REFUSED = 'REFUSED',
	CANCELLED = 'CANCELLED',
	UNKNOWN = 'UNKNOWN'
}

export enum CancellationReason {
	UNKNOWN = 'UNKNOWN',
	FINALISED = 'FINALISED',
	RECOMPUTED = 'RECOMPUTED',
	DELETED = 'DELETED'
}
