import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

import { LoginsRoute } from './logins/logins.route';
import { TeamsRoute } from './teams/teams.route';

export const ConfigurationRoute: RouteRecordRaw = {
	name: RouteKeys.CONFIGURATION,
	path: '/configuration',
	components: {
		default: () => import('./configuration.vue'),
		...defaultLayoutComponents
	},
	children: [LoginsRoute, TeamsRoute],
	redirect: LoginsRoute,
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasTeamsAndAccessAdminFeature.value),
		isNavEntry: true,
		label: 'admin.navigation.configuration',
		icon: () => $i('configuration'),
		requiresAuth: true,
		order: 50
	}
};
