import isEmpty from 'lodash/isEmpty';
import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { Optional, mapBy } from '@silae/helpers';
import { UserCompanyDTO } from '~/api';

import { useCompaniesStore } from './companies.store';
import { useRolesStore } from './roles.store';

export type CompanySelectionStore = {
	adminCompany: ComputedRef<Optional<UserCompanyDTO>>;
	adminCompanyId: ComputedRef<Optional<number>>;
	employeeCompany: ComputedRef<Optional<UserCompanyDTO>>;
	employeeCompanyId: ComputedRef<Optional<number>>;
	hasSelectedASingleCompany: ComputedRef<boolean>;
	managerCompanies: ComputedRef<Array<UserCompanyDTO>>;
	managerCompaniesIds: ComputedRef<Array<number>>;
	selectAdminCompany: (id?: number) => void;
	selectedCompanies: ComputedRef<Array<UserCompanyDTO>>;
	selectedCompaniesById: ComputedRef<Map<number, UserCompanyDTO>>;
	selectedCompanyIds: ComputedRef<Array<number>>;
	selectEmployeeCompany: (id?: number) => void;
	selectManagerCompanies: (ids?: Array<number>) => void;
};

export const useCompanySelectionStore = defineStore<'company-selection-store', CompanySelectionStore>('company-selection-store', () => {
	const { isEmployee, isManager, isAdmin } = storeToRefs(useRolesStore());
	const {
		employeeCompanies,
		employeeCompaniesById,
		managedCompanies,
		managedCompaniesById,
		administeredCompanies,
		administeredCompaniesById
	} = storeToRefs(useCompaniesStore());

	const _selectedEmployeeCompanyId: Ref<Optional<number>> = ref();
	const _selectedManagerCompaniesIds: Ref<Array<number>> = ref([]);
	const _selectedAdminCompanyId: Ref<Optional<number>> = ref();

	const allManagedCompaniesIds = computed(() => managedCompanies.value.map(it => it.companyId));

	const isValidEmployeeCompany = (id?: number) => id != undefined && employeeCompaniesById.value.has(id);
	const isValidManagerCompany = (id?: number) => id != undefined && managedCompaniesById.value.has(id);
	const isValidAdminCompany = (id?: number) => id != undefined && administeredCompaniesById.value.has(id);

	const selectEmployeeCompany = (id?: number) =>
		(_selectedEmployeeCompanyId.value = isValidEmployeeCompany(id) ? id : employeeCompanies?.value?.[0]?.companyId);

	const selectManagerCompanies = (ids?: Array<number>) => {
		const selectedIds = ids?.filter(isValidManagerCompany) ?? [];
		if (isEmpty(selectedIds)) {
			_selectedManagerCompaniesIds.value = allManagedCompaniesIds.value;
		} else {
			_selectedManagerCompaniesIds.value = selectedIds;
		}
	};

	const selectAdminCompany = (id?: number) =>
		(_selectedAdminCompanyId.value = isValidAdminCompany(id) ? id : administeredCompanies?.value?.[0]?.companyId);

	const employeeCompanyId = computed(() => _selectedEmployeeCompanyId.value);
	const employeeCompany: ComputedRef<Optional<UserCompanyDTO>> = computed(() =>
		_selectedEmployeeCompanyId.value != undefined ? employeeCompaniesById.value.get(_selectedEmployeeCompanyId.value) : undefined
	);
	const managerCompaniesIds = computed(() => _selectedManagerCompaniesIds.value);
	const managerCompanies: ComputedRef<Array<UserCompanyDTO>> = computed(
		() => (_selectedManagerCompaniesIds.value ?? []).map(id => managedCompaniesById.value.get(id)) as Array<UserCompanyDTO>
	);
	const adminCompanyId = computed(() => _selectedAdminCompanyId.value);
	const adminCompany = computed(() =>
		_selectedAdminCompanyId.value != undefined ? administeredCompaniesById.value.get(_selectedAdminCompanyId.value) : undefined
	);

	const selectedCompanies: ComputedRef<Array<UserCompanyDTO>> = computed(() => {
		if (isEmployee.value) {
			return employeeCompany.value != null ? [employeeCompany.value] : [];
		} else if (isManager.value) {
			return managerCompanies.value != null && managedCompanies.value.length > 0 ? [...managerCompanies.value] : [];
		} else if (isAdmin.value) {
			return adminCompany.value != null ? [adminCompany.value] : [];
		}
		return [];
	});

	const selectedCompanyIds: ComputedRef<Array<number>> = computed(() => (selectedCompanies.value ?? []).map(it => it.companyId));
	const hasSelectedASingleCompany = computed(() => selectedCompanyIds.value?.length === 1);

	return {
		adminCompany,
		adminCompanyId,
		employeeCompany,
		employeeCompanyId,
		hasSelectedASingleCompany,
		managerCompanies,
		managerCompaniesIds,
		selectAdminCompany,
		selectedCompanies,
		selectedCompaniesById: computed(() => mapBy<number>(selectedCompanies.value ?? [], 'companyId')),
		selectedCompanyIds,
		selectEmployeeCompany,
		selectManagerCompanies
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCompanySelectionStore, import.meta.hot));
