import { createI18n } from 'vue-i18n';

import ca from '~/i18n/ca.json';
import en from '~/i18n/en.json';
import es from '~/i18n/es.json';
import fr from '~/i18n/fr.json';
import pt from '~/i18n/pt.json';

const i18n = createI18n({
	fallbackLocale: 'fr', // set fallback locale
	globalInjection: true,
	legacy: false, // use composition API
	locale: 'fr', // set locale
	messages: {
		en,
		es,
		fr,
		ca,
		pt
	}
});

export { i18n };
