import { createApp } from 'vue';

import { setupIcons } from '@silae/composables';
import '~/plugins/polyfills';

import App from './App.vue';
import { ICON_LIBRARY, i18n, setupLogger, setupNotifications, setupSentry, setupVeeValidate, vuetify } from './plugins';
import { router } from './router';
import { setupErrorHandler } from './services/errors';
import { pinia } from './stores';
import './style';

setupLogger();

const app = createApp(App);

// setup Sentry as early as possible
setupSentry(app);
setupErrorHandler(app);

app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(i18n);

setupIcons(app, ICON_LIBRARY);
setupNotifications(app);
setupVeeValidate();

app.mount('#app');
