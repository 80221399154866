import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia';
import { ComputedRef, computed, ref, watch } from 'vue';

import { UserCompanyDTO } from '~/api';
import { Role } from '~/domain';
import { RoleTrackingContext } from '~/services';
import { useAuthenticationStore } from '~/stores/authentication.store';
import { useCompaniesStore } from '~/stores/companies.store';

export type RolesStore = {
	activeRole: ComputedRef<Role>;
	hasEmployeeRole: ComputedRef<boolean>;
	hasManagerRole: ComputedRef<boolean>;
	hasMultipleRoles: ComputedRef<boolean>;
	availableRoles: ComputedRef<Array<Role>>;
	isEmployee: ComputedRef<boolean>;
	isManager: ComputedRef<boolean>;
	isAdmin: ComputedRef<boolean>;
	roleTrackingContext: ComputedRef<RoleTrackingContext>;
	setActiveRole: (role: Role) => void;
};

const hasRole = (role: Role, companies: Array<UserCompanyDTO>) => {
	let check;
	switch (role) {
		case Role.EMPLOYEE:
			check = (company: UserCompanyDTO) => company.isEmployee;
			break;
		case Role.MANAGER:
			check = (company: UserCompanyDTO) => company.isManager;
			break;
		case Role.ADMIN:
			check = (company: UserCompanyDTO) => company.isAdmin;
			break;
	}
	return companies.some(check);
};

export const useRolesStore = defineStore<'roles-store', RolesStore>('roles-store', () => {
	const { companies } = storeToRefs(useCompaniesStore());
	const { isAuthenticated } = storeToRefs(useAuthenticationStore());

	const hasEmployeeRole = computed(() => hasRole(Role.EMPLOYEE, companies.value));
	const hasManagerRole = computed(() => hasRole(Role.MANAGER, companies.value));
	const hasAdminRole = computed(() => hasRole(Role.ADMIN, companies.value));

	const isEmployee = computed(() => activeRole.value === Role.EMPLOYEE);
	const isManager = computed(() => activeRole.value === Role.MANAGER);
	const isAdmin = computed(() => activeRole.value === Role.ADMIN);

	const hasMultipleRoles = computed(() => {
		const roles = [hasEmployeeRole.value, hasManagerRole.value, hasAdminRole.value];
		return roles.filter(Boolean).length > 1;
	});
	const availableRoles = computed(() => {
		const roles = [Role.EMPLOYEE, Role.MANAGER, Role.ADMIN];
		return roles.filter(role => {
			switch (role) {
				case Role.EMPLOYEE:
					return hasEmployeeRole.value;
				case Role.MANAGER:
					return hasManagerRole.value;
				case Role.ADMIN:
					return hasAdminRole.value;
			}
		});
	});

	const roleTrackingContext: ComputedRef<RoleTrackingContext> = computed(() => ({
		scope: isAdmin.value ? 'admin' : isManager.value ? 'manager' : 'personal'
	}));

	const activeRole = ref(Role.EMPLOYEE);
	const setActiveRole = (val: Role) => (activeRole.value = val ?? Role.EMPLOYEE);

	// enforce role swap if you have multiple accounts with mismatching roles
	watch(
		() => ({
			hasEmployeeRole: hasEmployeeRole.value,
			hasManagerRole: hasManagerRole.value,
			hasAdminRole: hasAdminRole.value,
			hasMultipleRoles: hasMultipleRoles.value,
			availableRoles: availableRoles.value,
			activeRole: activeRole.value,
			isAuthenticated: isAuthenticated.value
		}),
		data => {
			if (!data.isAuthenticated) {
				return;
			}
			if (data.activeRole === Role.EMPLOYEE && !data.hasEmployeeRole) {
				setActiveRole(Role.MANAGER);
			}
			if (data.activeRole === Role.MANAGER && !data.hasManagerRole) {
				setActiveRole(Role.ADMIN);
			}
			if (data.activeRole === Role.ADMIN && !data.hasAdminRole) {
				setActiveRole(Role.EMPLOYEE);
			}
		}
	);

	return {
		activeRole: computed(() => activeRole.value),
		hasEmployeeRole,
		hasManagerRole,
		hasMultipleRoles,
		availableRoles,
		isEmployee,
		isManager,
		isAdmin,
		roleTrackingContext,
		setActiveRole
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useRolesStore, import.meta.hot));
