import { defineStore } from 'pinia';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useHttpCache } from '@silae/composables';
import { Optional, error } from '@silae/helpers';
import { AxiosApiError, EmployeeLoginDTO, PreferredEmail, getAllEmployees$, getPreferredEmail$ } from '~/api';
import { useToasts } from '~/composables/messages.composables';
import { EmployeeLogin } from '~/domain';
import { employeeDtoToEmployee } from '~/utils';

import { Clearable } from '../store.domain';

export type EmployeeLoginsStore = Clearable & {
	fetchEmployeeLogins$: (companyId: number, invalidateCache?: boolean) => Observable<Array<EmployeeLogin>>;
	employeeLogins: ComputedRef<Array<EmployeeLogin>>;
	fetchPreferredEmail$: (companyId: number, invalidateCache?: boolean) => Observable<PreferredEmail>;
	preferredEmail: ComputedRef<Optional<PreferredEmail>>;
};

// TODO : faire un refactoring de cette fonctionnalité pour qu'elle soit plus générique
const asEmployeeLogin = (employee: EmployeeLoginDTO): EmployeeLogin => {
	return {
		...employee,
		...employeeDtoToEmployee(employee)
	};
};

export const useEmployeeLoginsStore = defineStore<'employee-logins', EmployeeLoginsStore>('employee-logins', () => {
	const { t } = useI18n();
	const { axiosError } = useToasts();

	const _employeeLogins: Ref<Array<EmployeeLogin>> = ref([]);
	const employeeLogins = computed(() => _employeeLogins.value);

	const _preferredEmail: Ref<Optional<PreferredEmail>> = ref();
	const preferredEmail = computed(() => _preferredEmail.value);

	const { cache$, clearCache } = useHttpCache<number, Array<EmployeeLoginDTO>>();
	const { cache$: preferredEmailCache$, clearCache: clearPreferredEmailCache } = useHttpCache<number, PreferredEmail>();

	const _fetchEmployeeLogins$ = (companyId: number, invalidateCache?: boolean): Observable<Array<EmployeeLogin>> => {
		if (invalidateCache) {
			clearCache();
		}

		return cache$(companyId, getAllEmployees$(companyId)).pipe(
			catchError((err: AxiosApiError) => {
				axiosError(err);
				return EMPTY;
			}),
			map(employees => employees.map(asEmployeeLogin)),
			tap(employeeLogins => {
				_employeeLogins.value = employeeLogins;
			})
		);
	};

	const _fetchEmailPreference$ = (companyId: number, invalidateCache?: boolean): Observable<PreferredEmail> => {
		if (invalidateCache) {
			clearPreferredEmailCache();
		}

		return preferredEmailCache$(companyId, getPreferredEmail$(companyId)).pipe(
			catchError((err: AxiosApiError) => {
				error({ title: t('common.feedback.error.title'), text: err.response?.data.error });
				return EMPTY;
			}),
			tap(preferredEmail => {
				_preferredEmail.value = preferredEmail;
			})
		);
	};

	const clear = () => {
		_employeeLogins.value = [];
		clearCache();
		clearPreferredEmailCache();
	};

	return {
		fetchEmployeeLogins$: _fetchEmployeeLogins$,
		employeeLogins,
		fetchPreferredEmail$: _fetchEmailPreference$,
		preferredEmail,
		clear
	};
});
