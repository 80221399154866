import { Observable } from 'rxjs';

import { useBackendHttpService } from '~/api';

import { PreferencesDto } from './preferences.dto.ts';

const API = `/v1/preferences`;

export function registerPreferences$(request: PreferencesDto): Observable<void> {
	const { http } = useBackendHttpService();
	return http.post(`${API}`, request);
}

export function getPreferences$(): Observable<PreferencesDto> {
	const { http } = useBackendHttpService();
	return http.get(`${API}`);
}
