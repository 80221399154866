import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { RouteKeys } from '~/router/routes.domain';

export const SecurityRoute: RouteRecordRaw = {
	name: RouteKeys.ACCOUNT_SECURITY,
	path: 'security',
	component: () => import('./security.vue'),
	meta: {
		label: 'account.navigation.security',
		icon: () => $i('padlock-close'),
		requiresAuth: true,
		isSettingsNavEntry: true,
		order: 2
	}
};
