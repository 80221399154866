import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

import { defaultLayoutComponents } from '../layout/layout';

export const LeaveDaysRequestsRoute: RouteRecordRaw = {
	name: RouteKeys.LEAVE_DAYS_REQUESTS,
	path: 'leave-days',
	components: {
		default: () => import('./leave-days.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			const { hasSelfOnboardingFeature } = useFeatures();
			return computed(() => isAdmin.value || hasSelfOnboardingFeature.value);
		}
	}
};
