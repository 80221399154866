<template>
	<v-tooltip data-test-id="tooltip-icon-button" location="top" :max-width="maxWidth" :text="title">
		<template #activator="{ props }">
			<sk-icon-btn
				:class="iconClass"
				:color
				:data-test-id="$attrs['data-test-id']"
				:disabled
				:icon
				:loading
				:size
				:variant
				v-bind="props"
				@click="click"
			/>
		</template>
	</v-tooltip>
</template>

<script lang="ts" setup>
import { VTooltip } from 'vuetify/components';

import { SkIconBtn } from '@silae/components';
import { useEventPropagation } from '@silae/composables';
import { UiSize, UiVariant } from '@silae/helpers';

withDefaults(
	defineProps<{
		color?: string;
		disabled?: boolean;
		icon?: string;
		iconClass?: string;
		size?: UiSize | string;
		title?: string;
		variant?: UiVariant;
		loading?: boolean;
		maxWidth?: number;
	}>(),
	{
		size: UiSize.SM,
		variant: UiVariant.TEXT,
		maxWidth: 200
	}
);

defineEmits(['click']);
const { click } = useEventPropagation(['click']);
</script>
