import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain.ts';

import { AdminLeaveDaysRoute } from '../leave-days/admin-leave-days.route.ts';
import { AdminRemoteWorkRoute } from '../mobilities/admin-remote-work.ts';

export const AdminRequestsRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_REQUESTS,
	path: '/admin-requests',
	components: {
		default: () => import('~/pages/requests/requests.vue'),
		...defaultLayoutComponents
	},
	children: [AdminLeaveDaysRoute, AdminRemoteWorkRoute],
	redirect: () => AdminLeaveDaysRoute,
	meta: {
		isForbidden: () => {
			return computed(() => Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value);
		},
		label: 'requests.navigation',
		icon: () => $i('list-bullets'),
		isNavEntry: true,
		order: 3,
		requiresAuth: true,
		requiresAdmin: true
	}
};
