import { DateTime } from 'luxon';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { Optional } from '@silae/helpers';
import {
	ISODateString,
	ValidateVariableElementsRequest,
	VariableElementsValidationProcessInformationDTO,
	fetchValidationProcessInformation$,
	validateVariableElements$
} from '~/api';

import { Clearable } from '../store.domain';

export type VariableElementsValidationStore = Clearable & {
	fetchValidationProcessInformation$: (
		companyId: number,
		basePeriod: ISODateString
	) => Observable<VariableElementsValidationProcessInformationDTO>;
	isValidationProcessing: ComputedRef<boolean>;
	validationRunAt: ComputedRef<Optional<DateTime>>;
	validateVariableElements$: (
		companyId: number,
		request: ValidateVariableElementsRequest
	) => Observable<VariableElementsValidationProcessInformationDTO>;
};

export const useVariableElementsValidationStore = defineStore<'variable-elements-validation-store', VariableElementsValidationStore>(
	'variable-elements-validation-store',
	() => {
		const _isValidationProcessing: Ref<boolean> = ref(false);
		const _currentValidationRunAt: Ref<Optional<DateTime>> = ref();

		const _fetchValidationProcessInformation$ = (companyId: number, basePeriod: ISODateString) =>
			fetchValidationProcessInformation$(companyId, basePeriod).pipe(
				tap(info => {
					_isValidationProcessing.value = info.isProcessing;
					_currentValidationRunAt.value = DateTime.fromISO(info.currentValidationRunAt);
				})
			);

		const _validateVariableElements$ = (companyId: number, request: ValidateVariableElementsRequest) => {
			_isValidationProcessing.value = true;
			_currentValidationRunAt.value = DateTime.now();
			return validateVariableElements$(companyId, request).pipe(
				switchMap(() => _fetchValidationProcessInformation$(companyId, request.period))
			);
		};

		const clear = () => {
			_isValidationProcessing.value = false;
			_currentValidationRunAt.value = undefined;
		};

		return {
			clear,
			fetchValidationProcessInformation$: _fetchValidationProcessInformation$,
			isValidationProcessing: computed(() => _isValidationProcessing.value),
			validationRunAt: computed(() => _currentValidationRunAt.value),
			validateVariableElements$: _validateVariableElements$
		};
	}
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useVariableElementsValidationStore, import.meta.hot));
