import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useFeatures } from '~/composables';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';

export const AdminLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_LEAVE_DAYS,
	path: 'admin-leave-days',
	components: {
		default: () => import('~/pages/leave-days/leave-days.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => {
			return computed(() => Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value);
		}
	}
};
