import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { mapBy } from '@silae/helpers';
import {
	AdminCompanyInformationDTO,
	CompanyInformationDTO,
	EmployeeCompanyInformationDTO,
	listAdminCompaniesInformation$,
	listEmployeeCompaniesInformation$,
	listManagedCompaniesInformation$
} from '~/api';
import { Clearable } from '~/stores/store.domain';

export type CompanyInformationStore = Clearable & {
	getEmployeeCompanyInformation$: (companyId: number) => Observable<EmployeeCompanyInformationDTO>;
	informationPerEmployeeCompany: ComputedRef<Map<number, EmployeeCompanyInformationDTO>>;
	informationPerManagedCompany: ComputedRef<Map<number, CompanyInformationDTO>>;
	informationPerAdminCompany: ComputedRef<Map<number, AdminCompanyInformationDTO>>;
	listEmployeeCompaniesInformation$: (companyIds: Array<number>) => Observable<Array<EmployeeCompanyInformationDTO>>;
	listManagedCompaniesInformation$: (companyIds: Array<number>) => Observable<Array<CompanyInformationDTO>>;
	listAdminCompaniesInformation$: (companyIds: Array<number>) => Observable<Array<AdminCompanyInformationDTO>>;
};

export const useCompanyInformationStore = defineStore<'company-information-store', CompanyInformationStore>(
	'company-information-store',
	() => {
		const employeeCompaniesInformation: Ref<Array<EmployeeCompanyInformationDTO>> = ref([]);
		const informationPerEmployeeCompany = computed(() => mapBy<number>(employeeCompaniesInformation.value, 'companyId'));
		const { cache$: employeeCompaniesInformationCache$, clearCache: clearEmployeeCompaniesInformationCache } = useHttpCache<
			string,
			Array<EmployeeCompanyInformationDTO>
		>(3600000);

		const _listEmployeeCompaniesInformation$ = (companyIds: Array<number>) => {
			const cacheKey = companyIds.join(',');
			const list$ =
				companyIds.length > 0
					? employeeCompaniesInformationCache$(cacheKey, listEmployeeCompaniesInformation$(companyIds))
					: of([]);

			return list$.pipe(
				tap(data => (employeeCompaniesInformation.value = [...employeeCompaniesInformation.value, ...data])),
				switchMap(_ => of(employeeCompaniesInformation.value.filter(info => companyIds.includes(info.companyId))))
			);
		};

		const managedCompaniesInformation: Ref<Array<CompanyInformationDTO>> = ref([]);
		const informationPerManagedCompany = computed(() => mapBy<number>(managedCompaniesInformation.value, 'companyId'));
		const { cache$: managedCompaniesInformationCache$, clearCache: clearManagedCompaniesInformationCache } = useHttpCache<
			string,
			Array<CompanyInformationDTO>
		>(3600000);

		const adminCompaniesInformation: Ref<Array<AdminCompanyInformationDTO>> = ref([]);
		const informationPerAdminCompany = computed(() => mapBy<number>(adminCompaniesInformation.value, 'companyId'));
		const { cache$: adminCompaniesInformationCache$, clearCache: clearAdminCompaniesInformationCache } = useHttpCache<
			string,
			Array<CompanyInformationDTO>
		>(3600000);

		const _listAdminCompaniesInformation$ = (companyIds: Array<number>) => {
			const cacheKey = `admin-${companyIds.join(',')}`;

			const list$ =
				companyIds.length > 0 ? adminCompaniesInformationCache$(cacheKey, listAdminCompaniesInformation$(companyIds)) : of([]);

			return list$.pipe(
				tap(data => (adminCompaniesInformation.value = [...adminCompaniesInformation.value, ...data])),
				switchMap(_ => of(adminCompaniesInformation.value.filter(info => companyIds.includes(info.companyId))))
			);
		};

		const _listManagedCompaniesInformation$ = (companyIds: Array<number>) => {
			const cacheKey = companyIds.join(',');
			const list$ =
				companyIds.length > 0 ? managedCompaniesInformationCache$(cacheKey, listManagedCompaniesInformation$(companyIds)) : of([]);

			return list$.pipe(
				tap(data => (managedCompaniesInformation.value = [...managedCompaniesInformation.value, ...data])),
				switchMap(_ => of(managedCompaniesInformation.value.filter(info => companyIds.includes(info.companyId))))
			);
		};

		const clear = () => {
			employeeCompaniesInformation.value = [];
			managedCompaniesInformation.value = [];
			adminCompaniesInformation.value = [];
			clearEmployeeCompaniesInformationCache();
			clearManagedCompaniesInformationCache();
			clearAdminCompaniesInformationCache();
		};

		const getEmployeeCompanyInformation$ = (companyId: number) =>
			_listEmployeeCompaniesInformation$([companyId]).pipe(switchMap(([data]) => of(data)));

		return {
			clear,
			getEmployeeCompanyInformation$,
			informationPerEmployeeCompany: computed(() => informationPerEmployeeCompany.value),
			informationPerManagedCompany: computed(() => informationPerManagedCompany.value),
			informationPerAdminCompany: computed(() => informationPerAdminCompany.value),
			listEmployeeCompaniesInformation$: _listEmployeeCompaniesInformation$,
			listManagedCompaniesInformation$: _listManagedCompaniesInformation$,
			listAdminCompaniesInformation$: _listAdminCompaniesInformation$
		};
	}
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCompanyInformationStore, import.meta.hot));
