import { fa } from 'vuetify/iconsets/fa-svg';

import { skIconAliases } from '@silae/composables';

export const vuetifyIcons = {
	defaultSet: 'fa',
	aliases: skIconAliases,
	sets: {
		fa
	}
};
