import { defineStore } from 'pinia';
import { Observable } from 'rxjs';

import { useHttpCache } from '@silae/composables';
import { PreferencesDto, getPreferences$ } from '~/api/preferences';
import { Clearable } from '~/stores/store.domain.ts';

export type PreferencesStore = Clearable & {
	fetchPreferences$: () => Observable<PreferencesDto>;
};

export const usePreferencesStore = defineStore<'preferences-store', PreferencesStore>('preferences-store', () => {
	const { cache$, clearCache } = useHttpCache<string, PreferencesDto>();

	const clear = () => {
		clearCache();
	};

	const _fetchPreferences$ = (): Observable<PreferencesDto> => {
		return cache$('preferences', getPreferences$());
	};

	return {
		clear,
		fetchPreferences$: _fetchPreferences$
	};
});
