import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { settingsLayoutComponents } from '~/pages/layout';
import { RouteKeys } from '~/router/routes.domain';

export const PreferencesRoute: RouteRecordRaw = {
	name: RouteKeys.ACCOUNT_PREFERENCES,
	path: 'preferences',
	components: {
		default: () => import('./preferences.vue'),
		...settingsLayoutComponents
	},
	meta: {
		label: 'account.navigation.preferences',
		icon: () => $i('cog'),
		requiresAuth: true,
		isSettingsNavEntry: true,
		order: 3
	}
};
