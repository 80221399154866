import { AxiosError, AxiosResponse } from 'axios';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiError, AxiosApiError } from '../defs';
import { useBackendHttpService } from '../http';
import { AuthenticateRequestDTO, B2CResult, B2CTokenDTO, ImpersonateRequestDTO } from './authentication.dto';

const CHANGE_PASSWORD_URL = import.meta.env.MS_AUTH_HEADLESS_CHANGE_PWD;
const JIT_MIGRATION_URL = import.meta.env.MS_AUTH_HEADLESS_JIT_MIGRATION;
const CLIENT_ID = import.meta.env.MS_AUTH_CLIENT_ID;
const SIGN_IN_URL = import.meta.env.MS_AUTH_HEADLESS_SIGNIN;
const HEADLESS_IMPERSONATE = import.meta.env.MS_AUTH_HEADLESS_IMPERSONATE;
const LOGOUT = import.meta.env.MS_AUTH_HEADLESS_LOGOUT;

export function applyJitMigration$(tokenHint: string): Observable<B2CResult> {
	return b2cAuth$(JIT_MIGRATION_URL, tokenHint, 'sign-in');
}

export function b2cChangePasswordHeadless$(login: string, currentPassword: string, newPassword: string): Observable<void> {
	const { http } = useBackendHttpService();
	const data = {
		username: login,
		password: currentPassword,
		new_password: newPassword,
		client_id: CLIENT_ID,
		scope: `openid ${CLIENT_ID} offline_access`,
		response_type: 'token id_token',
		grant_type: 'password'
	};
	return http
		.post(CHANGE_PASSWORD_URL, new URLSearchParams(data), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: false
		})
		.pipe(catchError(err => throwError(() => remapError(err))));
}

export function signInB2C$(request: AuthenticateRequestDTO): Observable<B2CTokenDTO> {
	const { http } = useBackendHttpService();
	const data = {
		username: request.login,
		password: request.password,
		client_id: CLIENT_ID,
		scope: `openid ${CLIENT_ID} offline_access`,
		response_type: 'token id_token',
		grant_type: 'password'
	};
	return http
		.post(SIGN_IN_URL, new URLSearchParams(data), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: false
		})
		.pipe(catchError(err => throwError(() => remapError(err))));
}

export function impersonateB2C$(request: ImpersonateRequestDTO): Observable<B2CTokenDTO> {
	const { http } = useBackendHttpService();
	const data = {
		username: request.login,
		password: request.password,
		impersonate: request.impersonate,
		client_id: CLIENT_ID,
		scope: `openid ${CLIENT_ID} offline_access`,
		response_type: 'token id_token',
		grant_type: 'password'
	};
	return http
		.post(HEADLESS_IMPERSONATE, new URLSearchParams(data), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: false
		})
		.pipe(catchError(err => throwError(() => remapError(err))));
}

export function signOutFromB2C$(): Observable<void> {
	const { protocol, host } = window.location;
	const post_redirect_uri = `${protocol}//${host}/sign-in`;
	const data: { [key: string]: string } = {
		post_redirect_uri
	};

	const queryParams = Object.keys(data)
		.map(k => k + '=' + encodeURI(data[k]))
		.join('&');

	const { http } = useBackendHttpService();
	return http
		.get(LOGOUT + `?${queryParams}`, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: false
		})
		.pipe(catchError(err => throwError(() => remapError(err))));
}

// TODO store refresh_token and use it to generate new id_token when expired
// use this method to obtain the new id_token
export function b2cRefreshHeadless$(refreshToken: string): Observable<B2CTokenDTO> {
	const { http } = useBackendHttpService();
	const data = {
		refresh_token: refreshToken,
		client_id: CLIENT_ID,
		response_type: 'id_token',
		grant_type: 'refresh_token',
		resource: CLIENT_ID
	};
	return http
		.post(SIGN_IN_URL, new URLSearchParams(data), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: false
		})
		.pipe(catchError(err => throwError(() => remapError(err))));
}
export function b2cAuth$(b2cUrl: string, tokenHint: string, path: string): Observable<B2CResult> {
	const { protocol, host } = window.location;
	const redirect_uri = `${protocol}//${host}/${path}`;
	const data = {
		id_token_hint: tokenHint,
		client_id: CLIENT_ID,
		nonce: 'defaultNonce',
		redirect_uri,
		scope: `openid`,
		response_type: 'id_token',
		response_mode: 'form_post'
	};

	const { http } = useBackendHttpService();
	return http
		.post(b2cUrl, new URLSearchParams(data), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			withCredentials: false
		})
		.pipe(
			map(it => {
				const parser = new DOMParser();
				const doc = parser.parseFromString(it, 'text/html');

				const getValue = (id: string) => (doc.getElementById(id) as HTMLInputElement)?.value;

				const error = getValue('error');
				const error_description = getValue('error_description');

				if (error || error_description) {
					throw { response: { data: { error, error_description } } };
				}

				return {
					id_token: getValue('id_token'),
					state: getValue('state')
				};
			}),
			catchError(err => throwError(() => remapError(err)))
		);
}

interface B2CError {
	error: string;
	error_description: string;
}

function convertErrorToObject(errorDescription: string): ApiError {
	const strings = errorDescription?.replaceAll('\r', '')?.split('\n');
	const codeAndMessage = strings[0];
	const code = codeAndMessage.includes(':') ? codeAndMessage.split(':')[0].trim() : '';
	const mess = codeAndMessage.includes(':') ? codeAndMessage.split(':')[1].trim() : codeAndMessage;
	return {
		errorCode: code,
		error: mess,
		path: '',
		status: 400,
		timestamp: -1
	};
}

/***
TODO write unit tests
	{

		"error": "access_denied",
		"error_description": "AADB2C90225: The username or password provided in the request are invalid.\r\nCorrelation ID: 21facc50-092e-46d5-bac7-78e6be53d7a9\r\nTimestamp: 2024-02-08 14:49:29Z\r\n"
	}
	{
		"error":"invalid_request",
		"error_description":"AADB2C90083: The request is missing required parameter: grant_type.\r\nCorrelation ID: 1acb0304-e765-450e-97d9-f61c18d9b6b3\r\nTimestamp: 2024-02-08 14:50:45Z\r\n"
	}
 */
function remapError(err: AxiosError<B2CError>): AxiosApiError {
	const data = err.response?.data;
	const errorDescription = data?.error_description;
	const errorBody = errorDescription != undefined ? convertErrorToObject(errorDescription) : undefined;
	const err2 = { ...err, response: err.response ?? ({} as AxiosResponse) };
	err2.response.data = errorBody;
	return err2;
}
