import { LeaveDaysTypeFamily } from '~/api';

export enum TimelineMode {
	LEAVE_DAYS = 'leave-days',
	REMOTE_WORK = 'remote-work'
}

export type AgendaMode = {
	mode: TimelineMode;
	families: Array<LeaveDaysTypeFamily>;
};

export const LEAVE_DAYS_FAMILIES = [
	LeaveDaysTypeFamily.ABSENCE,
	LeaveDaysTypeFamily.ABSENCE,
	LeaveDaysTypeFamily.LEGAL,
	LeaveDaysTypeFamily.OTHER,
	LeaveDaysTypeFamily.UNEMPLOYMENT,
	LeaveDaysTypeFamily.BUSINESS_TRAVEL,
	LeaveDaysTypeFamily.FAMILY,
	LeaveDaysTypeFamily.TRAINING,
	LeaveDaysTypeFamily.REST,
	LeaveDaysTypeFamily.HEALTH
];

export const REMOTE_WORK_FAMILIES = [LeaveDaysTypeFamily.REMOTEWORK];

export const LEAVE_DAYS_MODE: AgendaMode = {
	mode: TimelineMode.LEAVE_DAYS,
	families: LEAVE_DAYS_FAMILIES
};

export const REMOTE_WORK_MODE: AgendaMode = {
	mode: TimelineMode.REMOTE_WORK,
	families: REMOTE_WORK_FAMILIES
};
