import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { $i } from '@silae/composables';
import { useFeatures } from '~/composables';
import { LEAVE_DAYS_MODE, REMOTE_WORK_MODE } from '~/pages/agenda/agenda.domain.ts';
import { defaultLayoutComponents } from '~/pages/layout';
import { Devices } from '~/plugins';
import { RouteKeys } from '~/router/routes.domain';
import { useRolesStore } from '~/stores';

export const AdminAgendaRemoteWorkRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AGENDA_REMOTE_WORK,
	path: '/admin-agenda/remote-work',
	component: () => import('~/pages/agenda/agenda.vue'),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		}
	},
	props: route => ({ start: route.query.s, scope: route.query.gt, mode: REMOTE_WORK_MODE })
};

export const AdminAgendaLeaveDaysRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AGENDA_LEAVE_DAYS,
	path: '/admin-agenda/leave-days',
	component: () => import('~/pages/agenda/agenda.vue'),
	meta: {
		isForbidden: () => {
			const { isAdmin } = storeToRefs(useRolesStore());
			return computed(() => !isAdmin.value);
		}
	},
	props: route => ({ start: route.query.s, scope: route.query.gt, mode: LEAVE_DAYS_MODE })
};

export const AdminAgendaRoute: RouteRecordRaw = {
	name: RouteKeys.ADMIN_AGENDA,
	path: '/admin-agenda',
	components: {
		default: () => import('~/pages/agenda/agenda-router.vue'),
		...defaultLayoutComponents
	},
	meta: {
		isForbidden: () => computed(() => Devices().isMobile || !useFeatures().hasLeaveDayAdminFullFeature.value),
		isNavEntry: true,
		label: 'admin.navigation.agenda',
		icon: () => $i('calendar'),
		requiresAuth: true,
		order: 2
	},
	children: [AdminAgendaRemoteWorkRoute, AdminAgendaLeaveDaysRoute],
	redirect: AdminAgendaLeaveDaysRoute
};
