import { acceptHMRUpdate, defineStore } from 'pinia';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComputedRef, Ref, computed, ref } from 'vue';

import { useHttpCache } from '@silae/composables';
import { ISODateString, fetchCompanyEmployees$ } from '~/api';
import { Employee } from '~/domain';
import { employeeDtoToEmployee } from '~/utils';

import { Clearable } from '../store.domain';

export type EmployeesStore = Clearable & {
	employeesByCompany: ComputedRef<Map<number, Array<Employee>>>;
	fetchEmployees$: (companyId: number, basePeriod?: ISODateString, invalidate?: boolean) => Observable<Array<Employee>>;
};

export const useEmployeesStore = defineStore<'employee-store', EmployeesStore>('employee-store', () => {
	const { cache$: cacheEmployee$, clearCache: clearEmployee$ } = useHttpCache<string, Array<Employee>>();

	const _employeesByCompany: Ref<Map<number, Array<Employee>>> = ref(new Map());

	const clear = () => {
		_employeesByCompany.value.clear();
		clearEmployee$();
	};
	const fetchEmployees$ = (companyId: number, basePeriod?: ISODateString, invalidate?: boolean): Observable<Array<Employee>> => {
		if (invalidate) clear();

		const cacheKey = `${companyId}-${basePeriod}`;
		return cacheEmployee$(
			cacheKey,
			fetchCompanyEmployees$(companyId, basePeriod).pipe(
				map(employees => employees.map(employeeDtoToEmployee)),
				tap(employees => {
					_employeesByCompany.value.set(companyId, employees);
				})
			)
		);
	};

	return {
		clear,
		employeesByCompany: computed(() => _employeesByCompany.value),
		fetchEmployees$
	};
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useEmployeesStore, import.meta.hot));
